@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");
body {
  background-color: "#18181F" !important;
}
.App {
  /* text-align: center; */
  font-family: "Avenir";
  background-color: "#18181f" !important;
}
.App-logo {
  height: 40vmin;
}
@media (max-width: 991px) {
  .marginSmall {
    width: 90% !important;
  }
  .smallerImageSizeForSmallerDevices {
    width: 100px !important;
    height: 100px !important;
  }
  .smallerContainerForSmallerDevices {
    width: 149px !important;
    height: 62px !important;
    top: -20px !important;
  }
  .smallerAlumniNameForSmallerDevices {
    width: 124px !important;
    bottom: 3px !important;
  }
  .smallerForm {
    width: 90% !important;
  }
  .badgeForSmallerDevices {
    position: absolute !important;
    right: 30px !important;
    top: -8px !important;
    z-index: 10 !important;
    background-color: #800080 !important;
  }
  .smallerFont {
    font-size: 14px;
  }
  .smallerColMargin {
    margin-left: 0 !important;
  }
  .headerSmallMarginLeft {
    margin-left: 22px !important;
  }
  .smallerFontHeader {
    font-size: 24px !important;
  }
}

input,
select {
  background-color: #18181f !important;
  border-color: transparent !important;
  border-radius: 7px !important;
  height: 50px !important;
  color: white !important;
}
textarea {
  background-color: #18181f !important;
  border-color: transparent !important;
  border-radius: 7px !important;
  height: 180px !important;
  color: white !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

a,
a:visited {
  color: #000000 !important;
}

a:hover,
a:active {
  color: #000000 !important;
}

ul.nav li.active a {
  color: #000000 !important;
}

.col-half-offset {
  margin-left: 4.166666667%;
}

html {
  scroll-behavior: smooth;
}

/* Colors */

.text-grey {
  color: #adadc9 !important;
}
.text-dark-grey {
  color: #545468;
}
.text-purple {
  color: #7745eb;
}
.bg-dark-grey {
  background-color: #202029 !important;
}

.dd-button {
  border-radius: 9px;
  color: #adadc9 !important;
  background-color: #262636 !important;
  outline: none;
}

.dd-button:focus {
  border: none;
  outline: none;
}
.dd-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #adadc9;
  text-align: inherit;
  white-space: nowrap;
  background-color: #262636;
  border: 0;
}

.dd-item:hover {
  background-color: #20202e !important;
  color: #adadc9 !important;
}

.selected {
  color: #7745eb !important;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #4c4c66;
  border-radius: 50%;
  display: inline-block;
  margin-right: 11px;
  vertical-align: middle;
}

.selected-dot {
  height: 12px;
  width: 12px;
  background-color: #9e7bff;
  border-radius: 50%;
  margin-right: 11px;
  vertical-align: middle;
  display: inline-block;
}

.custom-btn {
  border-radius: 9px;
  color: #adadc9 !important;
  background-color: #262636 !important;
}

.custom-btn:hover {
  background-color: #20202e !important;
}

.custom-btn:focus {
  outline: none;
}

/* to make the carousel not take extra bottom space */
.rec-slider-container {
  height: 500px;
}
